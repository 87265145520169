<template>
    <b-container>
        <div style="margin:5px;">
            <b-row>
                <div class="card" style="margin:5px 20px;width:100%">
                    <div class="card-body">
                        <b-form @submit="onSubmit">
                            <div style="text-align:right">
                                <b-button
                                    v-b-toggle.editor
                                    @click="createUser"
                                    variant="warning"
                                    style="margin-left:5px;">
                                    新建
                                </b-button>
                            </div>
                        </b-form>
                    </div>
                </div>
            </b-row>
        </div>
        <div v-if="users.length > 0" 
            style="margin:5px auto;width:90%;">
            <b-row style="justify-content:space-around;">
                <b-table :items="users" :fields="fields" 
                    small responsive hover selectable select-mode="single"
                    @row-selected="onRowSelected">
                    <template #cell(status)="data">
                        <span :style="{color: getColor(data.item.status)}">
                            {{data.item.status == true ? "已启用" : "未启用"}}
                        </span>
                    </template>
                    <template #cell(is_admin)="data">
                        <span :style="{color: getColor(data.item.is_admin)}">
                            {{data.item.is_admin == true ? "是" : "否"}}
                        </span>
                    </template>
                    <template #cell(is_notify)="data">
                        <span :style="{color: getColor(data.item.is_notify)}">
                            {{data.item.is_notify == true ? "是" : "否"}}
                        </span>
                    </template>
                </b-table>
            </b-row>
        </div>
        <div v-else style="margin:10px;">
            <h6>没有用户</h6>
        </div>

         <b-sidebar id="editor" 
            :title="editForm.id > 0 ? '编辑用户' : '新建用户'" 
            right shadow
            v-model="showEditor"
            width="60%">
            <div class="px-3 py-2"
                style="margin:5px">
                <b-form @reset="onResetForm" ref="editorForm">
                    <b-form-group id="groupUserName" label="登录名:" label-for="username">
                        <b-form-input
                            id="username"
                            v-model="editForm.username"
                            placeholder="请输入登录名"
                            required
                            @blur="checkUserName"
                            :disabled="editForm.id > 0">
                        </b-form-input>
                    </b-form-group>
                    <div style="display:flex;align-items:center;flex-wrap:wrap;margin:20px;0px;">
                        <div style="margin-right:20px;margin-bottom:10px;display:flex;flex-direction:row;justify-content:flex-start;align-items:center;width:100%;">
                            <mt-switch v-model="editForm.status" style="width:20%;">是否启用</mt-switch>
                            <mt-switch v-model="editForm.is_notify" style="width:25%;">是否发送通知短信</mt-switch>
                            <mt-switch v-model="editForm.is_admin" style="width:20%;" v-if="editForm.username != 'dtang'">是否管理员</mt-switch>
                        </div>
                    </div>
                    <b-form-group id="groupEmail" label="邮箱:" label-for="email">
                        <b-form-input
                            id="email"
                            v-model="editForm.email"
                            placeholder="请输入邮箱"
                            required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="groupTelNumber" label="手机:" label-for="tel_number">
                        <b-form-input
                            id="tel_number"
                            v-model="editForm.tel_number"
                            placeholder="请输入手机"
                            required>
                        </b-form-input>
                    </b-form-group>
                    <b-form-group id="groupPassword" label="密码:" label-for="password" v-if="editForm.id == 0">
                        <b-form-input
                            id="password"
                            v-model="editForm.password"
                            placeholder="请输入密码"
                            type="password"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback :state="this.editForm.password.length >= 6">
                            密码长度至少六位
                        </b-form-invalid-feedback>
                    </b-form-group>
                    <b-form-group id="groupPasswordRepeat" label="确认密码:" label-for="passwordRepeat" v-if="editForm.id == 0">
                        <b-form-input
                            id="passwordRepeat"
                            v-model="editForm.passwordRepeat"
                            placeholder="请再次输入密码"
                            type="password"
                            required>
                        </b-form-input>
                        <b-form-invalid-feedback :state="this.editForm.password == this.editForm.passwordRepeat">
                            两次密码不一致
                        </b-form-invalid-feedback>
                    </b-form-group>
                </b-form>
            </div>
            <template>
                <div class="d-flex bg-senconday text-light align-items-center px-3 py-2">
                    <b-button @click="saveUser"
                        variant="primary" size="md"
                        style="margin-left:10px;margin-top:5px;">
                        保存
                    </b-button>
                    <b-button href="#"
                        @click="deleteUser"
                        style="margin-left:10px;margin-top:5px;"
                        v-if="this.editForm.id > 0 && this.editForm.is_admin == false">
                        删除
                    </b-button>
                    <b-button href="#" 
                        style="margin-left:10px;margin-top:5px;"
                        @click="popupPassword"
                        v-if="this.editForm.id > 0">
                        修改密码
                    </b-button>
                </div>
            </template>
         </b-sidebar>

         <b-modal id="user-info" title="修改密码" size="sm"
            hide-footer
            v-model="modalPasswordShow"
            v-if="selectedUser != null">
            <div class="card">
                <div class="card-body body">
                    <b-form @reset="onResetPasswordForm" ref="editorPasswordForm">
                        <b-form-group id="groupEditPassword" label="密码:" label-for="editPassword">
                            <b-form-input
                                id="editPassword"
                                v-model="editorPasswordForm.password"
                                placeholder="请输入密码"
                                type="password"
                                required>
                            </b-form-input>
                            <b-form-invalid-feedback :state="this.editorPasswordForm.password.length >= 6">
                                密码长度至少六位
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group id="groupEditPasswordRepeat" label="确认密码:" label-for="editPasswordRepeat">
                            <b-form-input
                                id="editPasswordRepeat"
                                v-model="editorPasswordForm.passwordRepeat"
                                placeholder="请再次输入密码"
                                type="password"
                                required>
                            </b-form-input>
                            <b-form-invalid-feedback :state="this.editorPasswordForm.password == this.editorPasswordForm.passwordRepeat">
                                两次密码不一致
                            </b-form-invalid-feedback>
                        </b-form-group>
                        <div class="d-flex bg-senconday text-light align-items-center px-3 py-2">
                            <b-button @click="savePassword"
                                variant="primary" size="md"
                                style="margin-left:10px;margin-top:5px;">
                                保存
                            </b-button>
                        </div>
                    </b-form>
                </div>
            </div>
         </b-modal>
    </b-container>
</template>

<script>
import { 
    Indicator, 
    MessageBox
} from 'mint-ui';

import {
    getUserList, getUser, saveUser, editUser, deleteUser
} from "api/user";

export default {
    data() {
        return {
            disabled: true,
            users: [],
            fields: [
                {
                    key: 'username',
                    label: '用户名'
                },
                {
                    key: 'email',
                    label: '邮箱'
                },
                {
                    key: 'tel_number',
                    label: '手机'
                },
                {
                    key: 'status',
                    label: '状态'
                },
                {
                    key: 'is_notify',
                    label: '是否发送通知短信'
                },
                {
                    key: 'is_admin',
                    label: '是否管理员'
                }
            ],
            selected: [],
            selectedUser: null,
            showEditor: false,
            editForm: {
                id: 0,
                username: '',
                email: '',
                tel_number: '',
                status: false,
                is_notify: false,
                is_admin: false,
                password: '',
                passwordRepeat: ''
            },
            editorPasswordForm: {
                id: 0,
                password: '',
                passwordRepeat: ''
            },
            total: 0,
            modalPasswordShow: false,
            isAdmin: false
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            let user = this.getLocalItem("user");
            getUser(user.userName, response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    if(data) {
                        this.isAdmin = data.is_admin;
                        if(this.isAdmin) {
                            this.search();
                        } else {
                            MessageBox('没有权限', '你没有权限查看此内容');
                        }
                    }
                }
            });
        },
        getColor(value) {
            return value ? "#28a745" : "#007bff";
        },
        checkUserName() {
            if(this.editForm.username == "") {
                MessageBox('请输入登录名', '请输入登录名');
                return;
            }
            getUser(this.editForm.username, response => {
                if(response.status == 200) {
                    let { data } = response.data;
                    if(data) {
                        MessageBox('登录名已存在', '登录名已存在');
                    }
                }
            });
        },
        onSubmit() {
            this.search();
        },
        search() {
            Indicator.open({ text: '加载中...', spinnerType: 'fading-circle' });
            getUserList(response => {
                if(response.status == 200) {
                    this.users = response.data.data;
                    this.total = response.data.total;
                }
                Indicator.close();
            });
        },
        onRowSelected(items) {
            this.selected = items;
            if(this.selected.length > 0) {
                this.selectedUser = this.selected[0];
                this.editUser(this.selectedUser.id, this.selectedUser);
                this.showEditor = true;
            }
        },
        popupPassword() {
            this.modalPasswordShow = !this.modalPasswordShow;
            this.onResetPasswordForm();
        },
        onResetForm(event) {
            if(event) {
                event.preventDefault();
            }
            this.editForm.id = 0;
            this.editForm.username = '';
            this.editForm.email = '';
            this.editForm.tel_number = '';
            this.editForm.status = true;
            this.editForm.is_notify = false;
            this.editForm.is_admin = false;
            this.editForm.password = '';
            this.editForm.passwordRepeat = '';
        },
        createUser() {
            this.onResetForm();
        },
        editUser(id, user) {
            this.editForm.id = id;
            this.editForm.username = user.username;
            this.editForm.email = user.email;
            this.editForm.tel_number = user.tel_number;
            this.editForm.status = user.status;
            this.editForm.is_notify = user.is_notify;
            this.editForm.is_admin = user.is_admin;
            this.editForm.password = '';
            this.editForm.passwordRepeat = '';
        },
        saveUser() {
            let form = this.$refs['editorForm'];
            let valid = form.checkValidity();
            if(valid) {
                valid = this.editForm.password == this.editForm.passwordRepeat;
                if(valid == false) {
                    MessageBox('保存失败', '两次输入密码不一致');
                    return;
                }
            }
            if(valid) {
                let saveObject = Object.assign({}, this.editForm);
                if(this.editForm.id == 0) {
                    saveUser(saveObject, response => {
                        if(response.status == 201) {
                            MessageBox('保存成功', '保存成功');
                            this.search();
                        }
                    });
                } else {
                    editUser(saveObject, response => {
                        if(response.status == 200) {
                            MessageBox('保存成功', '保存成功');
                            this.search();
                        }
                    });
                }
                this.showEditor = false;
            } else {
                MessageBox('保存失败', '保存失败，数据验证失败');
            }
        },
        onResetPasswordForm(event) {
            if(event) {
                event.preventDefault();
            }
            this.editorPasswordForm.id = this.editForm.id;
            this.editorPasswordForm.password = '';
            this.editorPasswordForm.passwordRepeat = '';
        },
        savePassword() {
            let form = this.$refs['editorPasswordForm'];
            let valid = form.checkValidity();
            if(valid) {
                valid = this.editorPasswordForm.password == this.editorPasswordForm.passwordRepeat;
                if(valid == false) {
                    MessageBox('保存失败', '两次输入密码不一致');
                    return;
                }
            }
            if(valid) {
                let saveObject = Object.assign({}, this.editorPasswordForm);
                console.log(saveObject);
                editUser(saveObject, response => {
                    if(response.status == 200) {
                        MessageBox('修改成功', '修改密码成功');
                        this.modalPasswordShow = false;
                        this.onResetPasswordForm();
                    }
                });
            }
        },
        deleteUser() {
            MessageBox.confirm(`确定删除此用户? 请注意此项操作不能回退！`).then(action => {
                deleteUser(this.editForm.id, response => {
                    if(response.status == 200) {
                        MessageBox('删除成功', '删除成功');
                        this.search();
                        this.showEditor = false;
                        this.onResetForm();
                    }
                });
            }).catch(()=>{});
        }
    }
}
</script>